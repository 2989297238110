<template>
  <div class="signup-comp">
    <!--remove autocomplete-->
    <input style="display:none" aria-hidden="true" />
    <input type="password" style="display:none" aria-hidden="true" />
    <!--End remove autocomplete-->
    <div class="pc signup-container">
      <div class="flex-center" style="padding-bottom: 24px;" v-if="false">
        <div v-for="(item, idx) in steps" :key="'step-' + idx" class="flex-align step">
          <div class="circle" :class="{ 'circle-select': step === idx }">{{ idx + 1 }}</div>
          <div class="body2-medium" :class="{ main: step === idx, sub5: step !== idx }">{{ item }}</div>
        </div>
      </div>
      <div class="signup-wrapper">
        <div class="subtitle3 main" style="margin-bottom: 40px">필수 정보 입력</div>

        <div v-for="(form, idx) in formData" :key="`form-${idx}`">
          <div class="signup-label">{{ form.label }}</div>
          <component
            :is="form.component_name"
            v-bind.sync="form"
            @etc="(e) => (form.etc = e)"
            @onEnter="signup"
            class="margin-top-8"
            :key="`form-${idx}`"
          ></component>
          <div v-if="form.label === '추천인코드'" class="flex-align margin-top-8" style="gap: 4px;">
            <div class="body6-medium tag">이벤트</div>
            <div class="body5 sub3">추천인 코드를 입력하면 50,000원 쿠폰을 드려요.</div>
          </div>
        </div>
        <div style="padding-top:20px">
          <lp-check-box-array v-bind.sync="allData" @onCheck="onCheckAll"></lp-check-box-array>
          <div class="divider-confirm"></div>
          <div v-for="(form, idx) in checkFormData" :key="`form-${idx}`">
            <component :is="form.component_name" v-bind.sync="form" class="margin-top-8" :key="`form-${idx}`">
            </component>
          </div>
        </div>
        <button class="button is-primary body2-medium" style="width:100%;margin-top:40px" @click="signup">
          회원가입 하기
        </button>
      </div>
    </div>
    <div class="mobile signup-container">
      <div class="signup-wrapper">
        <div class="h7 main" style="margin-bottom: 40px">필수 정보 입력</div>

        <div v-for="(form, idx) in formData" :key="`form-${idx}`">
          <div class="signup-label">{{ form.label }}</div>
          <component
            :is="form.component_name"
            v-bind.sync="form"
            @etc="(e) => (form.etc = e)"
            @onEnter="signup"
            class="margin-top-8"
            :key="`form-${idx}`"
          ></component>
          <div v-if="form.label === '추천인코드'" class="flex-align margin-top-8" style="gap: 4px;">
            <div class="body6-medium tag">이벤트</div>
            <div class="sub3" :class="isMobile ? 'body6' : 'body5'">추천인 코드를 입력하면 50,000원 쿠폰을 드려요.</div>
          </div>
        </div>
        <div style="padding-top:20px">
          <lp-check-box-array v-bind.sync="allData" @onCheck="onCheckAll"></lp-check-box-array>
          <div class="divider-confirm"></div>
          <div v-for="(form, idx) in checkFormData" :key="`form-${idx}`">
            <component :is="form.component_name" v-bind.sync="form" class="margin-top-8" :key="`form-${idx}`">
            </component>
          </div>
        </div>
        <button class="button is-primary body2-medium" style="height:48px;width:100%;margin-top:40px" @click="signup">
          회원가입 하기
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import LpText from '../../component/LpText';
import FormMixin from '../../../mixins/FormMixin';
import LpCheckBoxArray from '../../component/LpCheckBoxArray';
import LpDropDown from '../../LpDropDown';
export default {
  name: 'AddInfo',
  components: {
    LpText,
    LpDropDown,
    LpCheckBoxArray,
  },
  mixins: [FormMixin],
  created() {
    if (this.$store.getters.recmCode && this.$store.getters.recmCode !== '') {
      let recm = this.formData.find((i) => i.field === 'recommender');
      recm.value = this.$store.getters.recmCode;
    }
    this.init();
  },
  data() {
    return {
      step: 0,
      steps: ['정보입력', '가입완료'],
      formData: [
        {
          label: '이름',
          placeholder: '',
          maxLength: 30,
          field: 'name',
          type: 'text',
          disabled: true,
          value: this.$route.query.name,
          component_name: 'lp-text',
          errorMsg: '',
          validate: {
            num: true,
            alpha_g: true,
            alpha_s: true,
            special: true,
            max_length: 20,
            min_length: 1,
            valid_required: true,
            required: true,
          },
        },
        {
          label: '이메일',
          placeholder: '',
          maxLength: 100,
          field: 'username',
          type: 'email',
          value: this.$route.query.email,
          component_name: 'lp-text',
          errorMsg: '',
          disabled: true,
          validate: {
            valid_required: true,
            required: true,
          },
        },
        {
          label: '연락처',
          placeholder: '',
          maxLength: 30,
          field: 'phone',
          type: 'phone',
          value: '',
          component_name: 'lp-text',
          errorMsg: '',
          validate: {
            valid_required: true,
            required: true,
          },
        },
        {
          label: '가입경로',
          placeholder: '플랫폼파이를 어떻게 찾으셨나요?',
          maxLength: 30,
          field: 'funnel',
          type: 'dropdown',
          value: '',
          etc: '',
          component_name: 'lp-drop-down',
          errorMsg: '',
          options: [
            { value: 1, label: '뉴스, 기사' },
            { value: 2, label: '커뮤니티' },
            { value: 3, label: '인스타그램' },
            { value: 4, label: '페이스북' },
            { value: 5, label: '네이버 블로그' },
            { value: 6, label: '검색' },
            { value: 7, label: '친구 추천' },
            { value: 8, label: '타 사이트 하단 powered by 정보 확인' },
            { value: 9, label: '기타' },
          ],
          validate: {
            num: true,
            alpha_g: true,
            alpha_s: true,
            special: true,
            max_length: 30,
            min_length: 1,
            valid_required: true,
            required: true,
          },
        },
        {
          label: '추천인코드',
          placeholder: '추천인코드를 입력해주세요',
          maxLength: 20,
          field: 'recommender',
          type: 'text',
          value: '',
          component_name: 'lp-text',
          errorMsg: '',
          validate: {
            num: true,
            alpha_g: true,
            alpha_s: true,
            special: true,
            max_length: 10,
            min_length: 1,
            valid_required: false,
            required: false,
          },
        },
      ],
      allData: {
        field: 'all',
        type: 'check',
        value: false,
        component_name: 'lp-check-box-array',
        errorMsg: '',
        validate: {
          valid_required: true,
        },
        items: [
          {
            label: '전체 동의',
            labelStyle: {
              fontSize: '16px',
              fontWeight: 500,
              color: '#333333',
            },
          },
        ],
      },
      checkFormData: [
        {
          field: 'term',
          type: 'check',
          value: false,
          component_name: 'lp-check-box-array',
          errorMsg: '',
          validate: {
            valid_required: true,
          },
          items: [
            {
              label: '이용약관 동의',
              labelStyle: {
                fontSize: '15px',
                color: '#333333',
              },
            },
            {
              label: '약관보기',
              link: '/term',
              labelStyle: {
                color: '#828282',
                fontSize: '15px',
                textDecoration: 'underline',
                marginLeft: '8px',
              },
            },
          ],
        },
        {
          field: 'privacy',
          type: 'check',
          value: false,
          component_name: 'lp-check-box-array',
          errorMsg: '',
          validate: {
            valid_required: true,
          },
          items: [
            {
              label: '개인정보 처리방침 동의',
              labelStyle: {
                fontSize: '15px',
                color: '#333333',
              },
            },
            {
              label: '약관보기',
              link: '/privacy',
              labelStyle: {
                color: '#828282',
                fontSize: '15px',
                textDecoration: 'underline',
                marginLeft: '8px',
              },
            },
          ],
        },
        {
          field: 'marketing',
          type: 'check',
          value: false,
          component_name: 'lp-check-box-array',
          errorMsg: '',
          validate: {
            valid_required: true,
          },
          items: [
            {
              label: '마케팅 정보 수신 동의',
              labelStyle: {
                fontSize: '15px',
                color: '#333333',
              },
            },
            {
              label: '약관보기',
              link: '/marketing',
              labelStyle: {
                color: '#828282',
                fontSize: '15px',
                textDecoration: 'underline',
                marginLeft: '8px',
              },
            },
          ],
        },
      ],
    };
  },
  methods: {
    init() {
      if (this.$store.getters.trial) {
        this.formData = this.formData.filter((i) => i.field !== 'funnel');
      }
    },
    onCheckAll(val) {
      this.checkFormData.forEach((i) => (i.value = val));
    },
    signup() {
      if (!this.checkFormData[0].value) {
        this.toast('이용약관에 동의해주세요');
        return;
      } else if (!this.checkFormData[1].value) {
        this.toast('개인정보 처리방침에 동의해주세요');
        return;
      }
      let result = this.getParamData(this.formData);
      Object.assign(result.params, {
        marketing: this.checkFormData[this.checkFormData.findIndex((i) => i.field === 'marketing')].value,
      });

      let userData = {
        username: this.$route.query.user_id,
        name: this.$route.query.name,
        email: this.$route.query.email,
        login_type: this.$route.query.login_type,
      };
      let phone = this.formData.find(i => i.field === 'phone');
      if(phone) {
        userData.phone = phone.value;
      }
      let recommender = this.formData.find(i => i.field === 'recommender');
      if(recommender) {
        userData.recommender = recommender.value;
      }
      let funnel = this.formData.find(i => i.field === 'funnel');
      if(funnel) {
        userData.funnel = funnel.options.find((o) => o.value === funnel.value).label;
        if (userData.funnel === '기타') {
          userData.funnel = funnel.etc;
        }
      }
      this.$axios.post('auth/user/signup', userData).then((res) => {
        if (res.status === 200) {
          if (this.$store.getters.trial) {
            this.signupDone(res.data.token);
          } else {
            this.$router.replace(`/verify?token=${res.data.token}`);
          }
        }
      });
    },
    signupDone(token) {
      this.$axios.post('auth/user/verify/token/' + token, { type: 'launchpack' }).then((res) => {
        if (res.status === 200) {
          this.$store.commit('setUser', res.data.user);
          this.setAuthHeader();
          this.toast('회원가입이 완료되었습니다.');
          this.routerPush('/service_survey');
          this.$store.commit('setTrial', false);
        }
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~assets/css/lp_main'
.signup-container
  text-align center

.signup-wrapper
  border 1px solid $gray2
  border-radius 12px
  background-color white
  padding 40px

.divider
  width 100%
  height 1px
  background-color $gray1
  margin-bottom 40px

.divider-confirm
  margin-top 12px
  border-top 1px solid #e3e3e3
  padding-bottom 8px

.signup-label
  text-align left
  margin-top 16px
  color $sub3
  font-size 15px


@media (min-width:1025px)
  .signup-container
    margin-top 40px
    width 448px


@media (max-width:1024px)
  .signup-comp
    width 100%
    padding 40px 16px

  .signup-container
    width 100%

  .signup-wrapper
    padding 32px 20px

  .signup-label
    font-size 14px
.circle
  width 24px
  height 24px
  border-radius 100%
  line-height 24px
  text-align center
  background-color $sub5
  color white
  margin-right 4px

.circle-select
  background-color $main

.step
  margin-left 45px

.step:first-child
  margin-left 0


.tag
  width 42px
  height 20px
  padding 1px 4px
  border-radius 2px
  color $subcolor2
  background-color $subcolor50
</style>
